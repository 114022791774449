.userImg img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid #fff;
}
.HeaderUserImg {
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #fff;
  display: none;
  justify-content: space-between;
}
.HeaderUserImg h5 {
  margin-bottom: 0;
  margin-left: 15px;
  color: #fff;
}
.userImg span {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 935px) {
  .HeaderUserImg {
    display: flex;
  }
}
.HeaderUserImg .dropdown button {
  background: transparent;
  border: 0;
}
.HeaderUserImg .dropdown button::after {
  display: none;
}
.HeaderUserImg .dropdown button img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.HeaderUserImg .dropdown div span {
  width: 100%;
  padding: 5px;
}
.HeaderUserImg .dropdown div span button,
.HeaderUserImg .dropdown div span a {
  color: #000;
  padding: 5px 16px;
  width: 100% !important;
  border-radius: 10px;
  display: block;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.HeaderUserImg .dropdown div span a span,
.HeaderUserImg .dropdown div span button span {
  font-size: 18px !important;
}
.HeaderUserImg .dropdown div span a {
}
