#Header {
  height: var(--Height);
  display: flex;
  align-items: center;
  padding: 10px;
  box-shadow: var(--BoxShadow);
  transition: 0.5s;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  /* background-color: var(--WhiteColor); */
  /* border: 1px solid #ffffff99; */
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(25px);
}
#Header .Desktop {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#Header .Mobile {
  display: none;
}
#Header .Logo {
  display: flex;
  align-items: center;
  justify-content: center;
}
#Header ul li {
  margin: 0 5px;
}
#Header .Logo img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  padding: 2px;
  margin-right: 10px;
  border: 1px solid #fff;
}
#Header .Logo a {
  text-decoration: none;
  font-size: 30px;
  /* color: var(--MainColor); */
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

#Header .GoodBtn {
  border: 1px solid #ffffff3e !important;
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(25px);
  padding: 12px 20px;
  border-radius: 25px;
  box-shadow: var(--BoxShadow);
  background-color: transparent;
  transition: 0.5s;
  color: #fff;
}
#Header ul li:last-child button {
  padding: 10px 20px;
}
#Header .GoodBtn:hover {
  box-shadow: 0 0 0 transparent;
}
#Header ul {
  display: flex;
  list-style: none;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
}
#Header ul li a,
#Header ul li button {
  text-decoration: none;
  transition: var(--TransitionDuration);
  color: var(--MainColor);
}
#Header .Mobile ul li a.active {
  background-color: var(--MainColor);
  color: #fff !important;
}
#Header ul li a span {
  margin-right: 5px;
  transform: translateY(2px);
  display: inline-block;
}
#Header .Menu {
  display: none;
}
@media (max-width: 935px) {
  #Header {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: auto;
  }
  #Header .Desktop,
  #Header .container {
    display: none;
  }

  #Header .Mobile {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #Header .Mobile ul li a {
    border-radius: 4px;
    display: flex;
    flex-direction: column;
  }
  #Header .Mobile ul li a span {
    display: block;
  }
}
@media (max-width: 395px) {
  #Header .Mobile ul li a,
  #Header .Mobile ul li button {
    padding: 8px 10px;
  }
}
@media (max-width: 1190px) {
  #Header .Mobile ul li a,
  #Header .Mobile ul li button {
    padding: 8px 10px;
  }
  #Header ul li a span,
  #Header ul button span {
    display: none;
  }
}
#Header .dropdown button {
  background: transparent;
  border: 0;
}
 {
  /* 182px */
}
.dropdown-menu.show {
  width: 182px;
}
#Header .dropdown button::after {
  display: none;
}
#Header .dropdown button img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
#Header .dropdown div span {
  width: 100%;
  padding: 5px;
}
#Header .dropdown div span button,
#Header .dropdown div span a {
  color: #000;
  padding: 5px 16px;
  width: 100% !important;
  border-radius: 10px;
  display: block;
  text-decoration: none;
  display: flex;
  align-items: center;
}
#Header .dropdown div span a span,
#Header .dropdown div span button span {
  font-size: 16px !important;
}
#Header strong {
  font-size: 16px;
}
