#LibraryPage.Admin {
  padding: 0 10px;
}
#LibraryPage.Leader {
  padding: 0 10px;
  width: calc(100% - 300px);
  margin-top: 30px;
}
.AppBody[dir="rtl"] #LibraryPage.Leader {
  margin-right: auto;
}
.AppBody[dir="ltr"] #LibraryPage.Leader {
  margin-left: auto;
}
#LibraryPage .Image {
  position: relative;
  margin-bottom: 10px;
}
#LibraryPage .Image a {
  position: absolute;
  left: 5px;
  bottom: 5px;
}
#LibraryPage .Image img {
  width: 100%;
}
@media (max-width: 780px) {
  .AppBody[dir="rtl"] #LibraryPage.Leader {
    margin-right: auto;
    width: calc(100% - 60px) !important;
  }
  .AppBody[dir="ltr"] #LibraryPage.Leader {
    margin-left: auto;
    width: calc(100% - 60px) !important;
  }
}
