#Students .Students {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
}
#Students .Students .Student {
  display: flex;
  align-items: center;
  justify-content: space-between;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  border: 1px solid #ffffff8f;
  box-shadow: var(--BoxShadow);
  padding: 10px;
  border-radius: 5px;
  width: 100%;
  margin-bottom: 10px;
}
#Students .Students .Student h3 {
  font-size: 20px;
  width: calc(100% - 140px);
}
#Students .Students .Student h3 span {
  width: 100%;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
}
#Students .Students > div {
  width: 100%;
}
#Students .Students > div > div {
  width: 500px;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  border: 1px solid #ffffff8f;
  box-shadow: var(--BoxShadow);
  padding: 10px;
  border-radius: 5px;
}
#Students .Students h2 {
  width: 100%;
  padding-bottom: 10px;
  border-bottom: 1px solid;
  margin-bottom: 10px;
}
.Dark #Students .Students .Student,
.Dark #Students .Students > div > div {
  background-color: var(--VeryDarkColor);
}
#Students .Students button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  padding: 10px;
  color: #fff;
}
#Students div > button {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
}
@media (max-width: 890px) {
  #Students .Students > div > div {
    width: 100%;
  }
  #Students .Students .Student {
    width: 97%;
    margin-left: auto;
    margin-right: auto;
  }
}
