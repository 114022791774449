* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  transition: 0.1s;
  /* text-transform: capitalize; */
}

@font-face {
  font-family: "Lalezar";
  src: url("./assets/Fonts/Lalezar/Lalezar-Regular.ttf");
}

@font-face {
  font-family: "Rubik";
  src: url("./assets/Fonts/Rubik/Rubik-Black.ttf");
  src: url("./assets/Fonts/Rubik/Rubik-Bold.ttf");
  src: url("./assets/Fonts/Rubik/Rubik-ExtraBold.ttf");
  src: url("./assets/Fonts/Rubik/Rubik-Light.ttf");
  src: url("./assets/Fonts/Rubik/Rubik-Medium.ttf");
  src: url("./assets/Fonts/Rubik/Rubik-SemiBold.ttf");
  src: url("./assets/Fonts/Rubik/Rubik-Regular.ttf");
}

.Rubik {
  font-family: "Rubik", sans-serif;
}

.Lalezar {
  font-family: "Lalezar", sans-serif;
}
body {
  overflow-x: hidden;
}
:root {
  --Height: 65px;
  --RedColor: #ee2b47;
  --DarkColor: #34374c;
  --WhiteColor: #f6f6f6;
  --VeryDarkColor: #2c2e3e;
  /* --LightColor: #fdf9f9; */
  --LightColor: #e4e4e480;
  --MainColor: #4da3f0;
  /* --LightColor: #fdcedf; */
  /* --LightColor: #dbdbdb; */
  --TransitionDuration: 0.2s;
  --BoxShadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  /* --BoxShadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
}

body {
  /* background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url("https://sbjministry.com/Images/BG12.jpg"); */
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url("https://sbjministry.com/Images/BG@.jpg");
  /* background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url("http://localhost:3000/Images/BG@.jpg"); */
  background-position: 50% 30%;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
}
@media (max-width: 800px) {
  body {
  }
}
.Btn {
  border: 1px solid var(--MainColor);
  padding: 10px 20px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: var(--TransitionDuration);
  outline: 0;
  background-color: transparent;
}
.Btn:hover {
  background-color: var(--MainColor);
  color: #fff !important;
}
.Btn:hover a {
  color: #fff;
}
.Btn a {
  color: var(--MainColor);
}
.Btn.Btn-Light {
  border: 1px solid #fff;
  color: #fff;
}
.Btn.Btn-Light a {
  color: #fff !important;
}
.Btn.Btn-Light:hover {
  border: 1px solid var(--DarkColor);
}
.Btn.Btn-Light2:hover {
  background-color: var(--VeryDarkColor);
}
.Btn.Btn-Light a {
  color: #fff !important;
}
.AppBody.User {
  margin-top: var(--Height);
  height: auto;
  padding-bottom: 69px;
  overflow: auto;
}
.AppBody.Admin {
  /* margin-top: var(--Height); */
  height: auto;
  /* padding-bottom: 69px; */
  overflow: auto;
}

@media (max-width: 935px) {
  .AppBody.User {
    margin-top: 0;
    padding-bottom: 100px;
  }
}
.Btn:disabled {
  opacity: 0.5;
}
.Btn:disabled:hover {
  background-color: transparent;
  color: #2c2e3e !important;
}
.Layer {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 10000;
  background-color: #000000ec;
  transition: 0.5s;
  width: 100%;
}
.Layer.active {
  opacity: 0;
  z-index: -1;
  left: -100%;
}
.Dark .jWkLDY > span {
  color: #fff !important;
}
.guZdik {
  max-width: 100% !important;
  min-width: 100% !important;
}
@media (max-width: 500px) {
  .jWkLDY {
    flex-direction: column;
  }
}
@media (max-width: 400px) {
  .jWkLDY span {
    font-size: 11px !important;
  }
}
@media (max-width: 350px) {
  .jWkLDY span {
    font-size: 9px !important;
  }
}
.Img {
  border-radius: 50%;
  border: 1px solid #fff;
}
.Img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
::-webkit-scrollbar-track {
  border: 1px solid black;
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: #000000;
}
.Dark .text-light.IfDark {
  color: #fff !important;
}
.text-light.IfDark {
  color: #000 !important;
}
/* .css-1y7coo4-MuiButtonBase-root-MuiPaginationItem-root svg,
.css-1y7coo4-MuiButtonBase-root-MuiPaginationItem-root,
.css-1aczowp-MuiButtonBase-root-MuiPaginationItem-root,
.css-1aczowp-MuiButtonBase-root-MuiPaginationItem-root svg {
  color: #fff !important;
} */
/* .css-1y7coo4-MuiButtonBase-root-MuiPaginationItem-root,
.css-1aczowp-MuiButtonBase-root-MuiPaginationItem-root {
} */
#DashBord .Veiw .spinner-border {
  border: 3px solid #fff;
  border-top: 3px solid transparent;
}

.alert {
  background-color: transparent !important;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  border: 1px solid #ffffff8f;
  color: #fff !important;
}
.Pagination ul li button {
  border-radius: 50% !important;
  width: 40px !important;
  height: 40px !important;
  font-size: 20px !important;
  border: 1px solid #fff !important;
  color: #fff;
}
.Pagination ul li button svg {
  color: #fff !important;
}
.spinner-border {
  border: 3px solid #fff !important;
  border-left: 3px solid transparent !important;
  width: 40px !important;
  height: 40px !important;
}
.SpecialSpinner {
  border: 5px solid #ffffff !important;
  border-left: 5px solid transparent !important;
  width: 70px !important;
  height: 70px !important;
}
