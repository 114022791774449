.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: auto;
  width: 300px;
  z-index: 996;
  transition: all 0.3s;
  padding: 20px;
  overflow-y: auto;
  box-shadow: 0px 0px 20px rgba(1, 41, 112, 0.1);
  border: 1px solid #ffffff99;
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(25px);
  border-radius: 5px;
}
.sidebar.Right {
  right: 0;
  left: auto;
}
.sidebar h2 {
  color: #fff;
}

.sidebar::-webkit-scrollbar {
  width: 5px;
}

.sidebar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.sidebar::-webkit-scrollbar-thumb {
  background-color: rgb(255, 255, 255);
}
@media (min-width: 1200px) {
  #main,
  #footer {
    margin-left: 300px;
  }
}

@media (max-width: 1199px) {
  .toggle-sidebar .sidebar {
    left: 0;
  }
}

@media (min-width: 1200px) {
  .toggle-sidebar #main,
  .toggle-sidebar #footer {
    margin-left: 0;
  }

  .toggle-sidebar .sidebar {
    left: -300px;
  }
}

.sidebar-nav {
  padding: 0;
  margin: 0;
  list-style: none;
}
@media (max-width: 780px) {
  .sidebar-nav li a span,
  .sidebar-nav button span {
    display: none;
  }
  .Logo h2 {
    display: none;
  }
  .sidebar {
    width: 60px;
    padding: 8px;
    overflow-x: hidden;
  }
  .sidebar-nav li a,
  .sidebar-nav button {
    padding: 5px !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .sidebar-nav .nav-heading {
    margin: 0 0 10px 5px !important;
  }
  .sidebar img {
    margin-left: -3px;
  }
  .sidebar .Logo {
    padding: 0 !important;
  }
}
.sidebar-nav li {
  padding: 0;
  margin: 0;
  list-style: none;
  height: 40px;
}
.sidebar.Right .sidebar-nav li {
  direction: rtl;
}

.sidebar-nav .nav-item {
  margin-bottom: 5px;
}

.sidebar-nav .nav-heading {
  font-size: 11px;
  text-transform: uppercase;
  color: #899bbd;
  font-weight: 700;
  margin: 8px 15px;
}
.sidebar.Right .sidebar-nav .nav-heading {
  text-align: right;
}
.sidebar-nav .nav-link {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 600;
  /* color: #4154f1; */
  transition: 0.3;
  /* background: #f6f9ff; */
  padding: 10px 15px;
  border-radius: 4px;
  height: 100%;
}

.sidebar-nav .nav-link i {
  font-size: 16px;
  margin-right: 10px;
  color: #4154f1;
}

.sidebar-nav .nav-link.collapsed {
  color: #fff;
  /* background: #fff; */
}

.sidebar-nav .nav-link.collapsed i {
  color: #899bbd;
}

.sidebar-nav .nav-link:hover,
.sidebar-nav li a.active {
  color: #4154f1 !important;
  background: #f6f9ff !important;
}

.sidebar-nav .nav-link:hover i,
.sidebar-nav li a.active svg {
  color: #4154f1;
}

.sidebar-nav .nav-link .bi-chevron-down {
  margin-right: 0;
  transition: transform 0.2s ease-in-out;
}

.sidebar-nav .nav-link:not(.collapsed) .bi-chevron-down {
  transform: rotate(180deg);
}

.sidebar-nav .nav-content {
  padding: 5px 0 0 0;
  margin: 0;
  list-style: none;
}

.sidebar-nav .nav-content a {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  color: #012970;
  transition: 0.3;
  padding: 10px 0 10px 40px;
  transition: 0.3s;
}

.sidebar-nav .nav-content a i {
  font-size: 6px;
  margin-right: 8px;
  line-height: 0;
  border-radius: 50%;
}

.sidebar-nav .nav-content a:hover,
.sidebar-nav .nav-content a.active {
  color: #4154f1;
}

.sidebar-nav .nav-content a.active i {
  background-color: #4154f1;
}
