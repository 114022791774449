@font-face {
  font-family: "Dancing_Script";
  src: url("../../assets/Fonts/Dancing_Script/static/DancingScript-Medium.ttf");
}

.HomePage {
  width: 100%;
  padding: 10px;
  /* background-image: url("http://localhost:3000/Images/BGWelcome.jpeg"); */
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 137px);
  font-family: "Dancing_Script", sans-serif;
}
.HomePage h1 {
  font-size: 80px;
  animation: MoveMent 3s linear infinite;
}
@keyframes MoveMent {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(30px);
  }
  100% {
    transform: translateY(0);
  }
}
.HomePage.Admin {
  width: calc(100% - 300px);
}
@media (max-width: 780px) {
  .HomePage.Admin {
    width: calc(100% - 60px);
  }
}
.DeskTop {
  display: block;
}
.Mobile {
  display: none;
}
@media (max-width: 935px) {
  .DeskTop {
    display: none;
  }
  .Mobile {
    display: block;
  }
}
