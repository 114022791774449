#PlayLists {
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
}
#PlayLists .PlayLists {
  display: flex;
  flex-direction: column;
}
#PlayLists .PlayLists .PlayList {
  width: 504px;
  height: 140px;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  border: 1px solid #ffffff86;
  border-radius: 10px;
  padding: 10px;
  margin: 16px auto;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 0;
  align-items: center;
}

#PlayLists .PlayLists .PlayList img {
  height: 115px;
  width: 115px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
  object-position: top;
}
#PlayLists .PlayLists .PlayList p {
  opacity: 0.8;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  font-style: italic;
  margin-right: 10px;
}
.AppBody[dir="ltr"] #PlayLists .PlayLists .PlayList p {
  opacity: 0.8;
  font-style: italic;
  flex-direction: row;
  justify-content: flex-start;
}
#PlayLists .PlayLists .PlayList span:first-child {
  margin-right: 15px;
  position: relative;
}
#PlayLists .PlayLists .PlayList span:first-child::after {
  content: "";
  width: 6px;
  height: 6px;
  background-color: rgb(255, 255, 255);
  position: absolute;
  border-radius: 50%;
  right: -12px;
  top: 55%;
  transform: translateY(-50%);
}
/* #PlayLists .PlayLists .PlayList > div {
  width: 90%;
} */
#PlayLists .PlayLists .PlayList h2 {
  white-space: nowrap;
  overflow: hidden;
  width: 90%;
  text-overflow: ellipsis;
  font-size: 20px;
}
#PlayLists .PlayLists .PlayList button {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  padding: 8px;
  color: #fff;
  margin: 0;
}
#PlayLists .PlayLists .PlayList .Actions {
  position: absolute;
  display: flex;
  flex-direction: column;
  right: 5px;
  top: 5px;
}
.AppBody[dir="rtl"] #PlayLists .PlayLists .PlayList .Actions {
  left: 5px;
  right: auto;
}
#PlayLists .PlayLists .PlayList > div {
  width: 65%;
}
@media (max-width: 700px) {
  #PlayLists .PlayLists .PlayList {
    width: 95%;
    height: 100px;
  }
  #PlayLists .PlayLists .PlayList img {
    width: 90px;
    height: 90px;
  }
}
