.AppBody.Dark {
  /* background-color: var(--DarkColor);
 */
}
.AppBody.User {
  height: calc(100vh - var(--Height));
  /* padding-top: 20px; */
}
.AppBody.Admin {
  min-height: 100vh;
  height: auto;
  /* padding-top: 20px; */
}
.AppBody {
  color: #fff;
}
