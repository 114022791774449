#LibraryPage {
  margin-top: 10px;
}

.Libraries {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}
/*  */


.Libraries .Library {
  width: 500px;
  height: auto;
  border-radius: 5px;
  /* background-color: var(--LightColor); */
  padding: 1px;
  margin-bottom: 10px;
  overflow: hidden;
  box-shadow: var(--BoxShadow);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  border: 1px solid #ffffffc6;
}
.Dark .Libraries .Library {
  background-color: var(--VeryDarkColor);
}
.Libraries .Library .ToggleView {
  background-color: transparent;
  color: #fff;
  border: 0;
}
.Libraries .Library p {
  white-space: break-spaces;
  margin-bottom: 0;
}
.Libraries .Library .ToggleView {
  background-color: transparent;
  color: #000;
  border: none;
}
.Dark .Libraries .Library .ToggleView {
  color: #fff;
}
.Libraries .Library .body {
  padding: 10px;
  margin-top: -9px;
}
.Libraries .Library .Actions > button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  padding: 10px;
}
@media (max-width: 550px) {
  .Libraries .Library {
    width: 95%;
  }
  .Libraries .Library iframe.SoundCloud {
    height: 100px;
  }
  .Libraries .Library iframe.PDF {
    height: 400px;
  }
  .Libraries .Library iframe.Media {
    height: 300px;
  }
}
@media (max-width: 450px) {
  .Libraries .Library iframe.SoundCloud {
    height: 100px;
  }
  .Libraries .Library iframe.PDF {
    height: 300px;
  }
  .Libraries .Library iframe.Media {
    height: 200px;
  }
}
