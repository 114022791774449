#SignInPage {
  height: 100vh;
  background-image: linear-gradient(
    to left,
    var(--DarkColor),
    var(--VeryDarkColor)
  );
  display: flex;
  align-items: center;
  justify-content: center;
  /* color: #fff; */
}
#SignInPage .Form {
  padding: 50px 30px;
  background-color: #ffffff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: var(--BoxShadow);
  width: 500px;
}
#SignInPage .Form h2 {
  font-size: 45px;
  font-weight: 900;
  text-shadow: 3px 3px #c29f9f;
  margin-bottom: 10px;
  text-align: center;
}
#SignInPage .Form button {
  margin-top: 20px;
}
@media (max-width: 550px) {
  #SignInPage .Form {
    width: 90%;
  }
}
