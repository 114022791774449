#NotFoundPage {
  min-height: calc(100vh - var(--Height));
  height: auto;
  max-height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ee2b47;
  color: #fff;
  flex-direction: column;
  text-align: center;
  padding: 10px;
}
#NotFoundPage.Admin.RTL {
  width: calc(100% - 300px);
  margin-right: auto;
  min-height: 100vh;
}
#NotFoundPage.Admin.LTR {
  width: calc(100% - 300px);
  margin-left: auto;
  min-height: 100vh;
}

#NotFoundPage h2 {
  font-weight: 900;
  font-size: 170px;
  text-shadow: 10px 10px #000;
}

#NotFoundPage h3 {
  font-weight: 900;
  font-size: 80px;
}
