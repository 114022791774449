#FormsStudentPage {
  margin-top: 20px;
}
#FormsStudentPage.Leader {
  width: calc(100% - 300px);
}
#FormsStudentPage.Left {
  margin-right: auto;
}
#FormsStudentPage.Right {
  margin-left: auto;
}
@media (max-width: 780px) {
  #FormsStudentPage.Leader {
    width: calc(100% - 60px);
  }
}
#FormsStudentPage .Forms {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#FormsStudentPage .Forms .Form {
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid #ffffff99;
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(25px);
  box-shadow: var(--BoxShadow);
  padding: 10px;
  border-radius: 5px;
  position: relative;
  padding-bottom: 40px;
}
#FormsStudentPage .Forms .Form button {
  border: 1px solid #ffffff99;
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(25px);
  background-color: transparent;
  box-shadow: var(--BoxShadow);
  opacity: 0.8;
  transition: 0.5s;
}
#FormsStudentPage .Forms .Form button:hover {
  opacity: 1;
  transform: scale(1.04);
}
#FormsStudentPage .Forms .Form a {
  text-decoration: none;
  color: #fff;
}
.Dark #FormsStudentPage .Forms .Form {
  background-color: var(--VeryDarkColor);
}
#FormsStudentPage .Forms .Form p {
  margin-left: 10px;
}
#FormsStudentPage .Forms .Form p {
  margin-left: 10px;
}
.AppBody[dir="rtl"] #FormsStudentPage .Forms .Form p {
  margin-left: 0;
  margin-right: 10px;
}
#FormsStudentPage .Forms .Form .Time {
  position: absolute;
  bottom: 5px;
  right: 5px;
}
@media (max-width: 480px) {
  #FormsStudentPage .Forms .Form {
    width: 95%;
  }
}
