#Groups .Groups {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}
#Groups .Groups > div {
  width: 100%;
}
#Groups .Groups .Group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* background-color: var(--LightColor); */
  box-shadow: var(--BoxShadow);
  padding: 10px;
  border-radius: 5px;
  width: 400px;
  margin-bottom: 10px;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  border: 1px solid #ffffff8f;
}

.Dark #Groups .Groups .Group {
  background-color: var(--VeryDarkColor);
}
#Groups .Groups .Group button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  padding: 10px;
  color: #fff;
}

@media (max-width: 785px) {
  #Groups .Groups .Group {
    width: 97%;
    margin-left: auto;
    margin-right: auto;
  }
}
