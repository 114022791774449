#FormsPage.Admin {
  padding: 0 10px;
}
#FormsPage .Forms {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  flex-direction: column;
  width: 100%;
}
#FormsPage .Forms > div {
  width: 100%;
}
#FormsPage .Forms .Form {
  display: flex;
  align-items: center;
  justify-content: space-between;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  border: 1px solid #ffffff8f;
  box-shadow: var(--BoxShadow);
  padding: 10px;
  border-radius: 5px;
  width: 400px;
  margin-bottom: 10px;
}
#FormsPage .Forms .Form h3 {
  width: calc(100% - 151px);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
#FormsPage .Forms .Form .Actions {
  width: 151px;
}
.Dark #FormsPage .Forms .Form {
  background-color: var(--VeryDarkColor);
}
#FormsPage .Forms .Form button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  padding: 10px;
  color: #fff;
}

@media (max-width: 785px) {
  #FormsPage .Forms .Form {
    width: 97%;
    margin-left: auto;
    margin-right: auto;
  }
}
.FormData {
  width: 100%;
}
.FormData .ViewData {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;
}
.FormData .FirstCard {
  background: rgba(0, 0, 0, 0.125);
  margin: 5px;
  border-radius: 5px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  width: 350px;
}
.FormData .FirstCard > div {
  display: flex;
  flex-direction: column;
}

.FormData .FirstCard > div span:last-child {
  margin-left: 5px;
  margin-top: -5px;
}
.FormData .Pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}
.FormData .FirstCard .Answers {
  display: none;
}
.FormData .FirstCard .Answers.Show {
  display: block;
}
