#Levels .Levels {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}
#Levels .Levels > div {
  width: 100%;
}
#Levels .Levels .Level {
  display: flex;
  align-items: center;
  justify-content: space-between;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  border: 1px solid #ffffff8f;
  box-shadow: var(--BoxShadow);
  padding: 10px;
  border-radius: 5px;
  width: 400px;
  margin-bottom: 10px;
}

/* .Dark #Levels .Levels .Level {
  background-color: var(--VeryDarkColor);
} */
#Levels .Levels .Level button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  padding: 10px;
  color: #fff;
}

@media (max-width: 785px) {
  #Levels .Levels .Level {
    width: 97%;
    margin-left: auto;
    margin-right: auto;
  }
}
