#DashBord {
  display: flex;
  height: 100%;
  align-items: flex-start;
  justify-content: space-between;
}
#DashBord .ASideLinks {
  width: 350px;
  background-color: var(--VeryDarkColor);
  height: calc(100vh - var(--Height));
  position: fixed;
  top: var(--Height);
}
#DashBord .ASideLinks ul.LargeFont li a {
  font-size: 25px;
}
#DashBord .ASideLinks ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0;
  padding: 0;
  height: 100%;
  overflow-y: auto;
  padding: 20px 10px;
}
#DashBord .ASideLinks ul li {
  margin-bottom: 10px;
}
#DashBord .ASideLinks ul li a {
  color: #fff;
  text-decoration: none;
  justify-content: space-between;
}
#DashBord .ASideLinks ul li a.active {
  background-color: var(--DarkColor);
  color: #fff !important;
}
#DashBord .ASideLinks ul li a span {
  margin-right: 10px;
}
#DashBord .Veiw {
  width: calc(100% - 300px);
  /* height: calc(100vh - var(--Height)); */
  padding: 20px;
  overflow: auto;
  overflow-x: hidden;
  margin-left: 300px;
}
#DashBord .Veiw > * {
  margin-top: 20px;
}
.AppBody[dir="rtl"] #DashBord .Veiw {
  margin-left: auto;
  margin-right: 300px;
}
#ProfilePage {
  margin-top: 10px;
}
#ProfilePage .GoodStyle {
  -webkit-backdrop-filter: blur(30px);
  margin-top: 10px;
  backdrop-filter: blur(30px);
  border: 1px solid #ffffff8f;
  padding: 10px;
  border-radius: 5px;
}
#ProfilePage .Score {
  -webkit-backdrop-filter: blur(30px);
  margin-top: 10px;
  backdrop-filter: blur(30px);
  border: 1px solid #ffffff8f;
  padding: 10px;
  border-radius: 5px;
}
#ProfilePage .FormsType div {
  -webkit-backdrop-filter: blur(30px);
  margin-top: 10px;
  backdrop-filter: blur(30px);
  border: 1px solid #ffffff8f;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  user-select: none;
}
#ProfilePage.Admin {
  width: calc(100% - 300px);
}
#ProfilePage input {
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  border: 1px solid #ffffff8f;
  box-shadow: var(--BoxShadow);
  background-color: transparent;
  color: #fff;
}
#ProfilePage.Left {
  margin-right: auto;
}
#ProfilePage.Right {
  margin-left: auto;
}
@media (max-width: 780px) {
  #DashBord {
    flex-direction: column;
  }
  #DashBord .ASideLinks {
    width: 300px;
    height: 100vh;
    z-index: 2;
    top: 0;
    left: -300px;
    transition: 0.4s;
  }
  #DashBord .ASideLinks::before {
    content: "";
    position: fixed;
    width: calc(100% - 300px);
    height: 100vh;
    background: #00000087;
    right: -100%;
    top: 0;
    border: 0;
    transition: 0.3s;
  }
  #DashBord .ASideLinks:has(.Show)::before {
    right: 0;
  }
  #DashBord .ASideLinks:has(.Show) {
    left: 0;
  }
  #ProfilePage.Admin {
    width: calc(100% - 60px);
  }
  .AppBody[dir="rtl"] #DashBord .Veiw {
    margin-right: auto;
    width: calc(100% - 60px) !important;
  }
  .AppBody[dir="ltr"] #DashBord .Veiw {
    margin-left: auto;
    width: calc(100% - 60px) !important;
  }

  .AsideHeader .sidebar.Right {
    right: -300px !important;
  }
  .AsideHeader .sidebar {
    left: -300px !important;
  }
  .AppBody[dir="rtl"] .ViewMessages.Leader {
    margin-right: auto;
    width: calc(100% - 60px) !important;
  }
  .AppBody[dir="ltr"] .ViewMessages.Leader {
    margin-left: auto;
    width: calc(100% - 60px) !important;
  }
}
@media (max-width: 935px) {
  #DashBord .ASideLinks {
    top: 0;
  }
}
.Schools {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.Schools .School {
  width: 300px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 25px;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  border: 1px solid #ffffff81;
  box-shadow: var(--BoxShadow);
  margin: 10px;
}
.Dark .Schools .School {
  background-color: var(--VeryDarkColor);
}
@media (max-width: 350px) {
  .Schools .School {
    width: 98%;
  }
}
.Schools .School .Image {
  width: 180px;
  height: 180px;
  border-radius: 50%;
  object-fit: contain;
  border: 1px solid #fff;
  margin-bottom: 20px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.SendSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.SendSection input {
  width: calc(100% - 60px);
}
#Notifications {
  height: calc(100vh - var(--Height) - 40px);
  display: flex;
  /* align-items: center;
  justify-content: center; */
  flex-direction: column;
  width: 900px;
  margin: auto;
}
.ViewMessages {
  height: calc(100% - 70px);
  overflow: auto;
  padding: 10px;
  width: 100%;
  margin-top: 30px;
}
.ViewMessages.Leader {
  width: calc(100% - 300px) !important;
  overflow: auto;
  padding: 10px;
  margin-top: 30px !important;
}
.AppBody[dir="rtl"] .ViewMessages.Leader {
  margin-right: auto;
}
.AppBody[dir="ltr"] .ViewMessages.Leader {
  margin-left: auto;
}
.ViewMessages .Message {
  width: 100%;
  background-color: var(--MainColor);
  box-shadow: var(--BoxShadow);
  padding: 15px;
  border-radius: 20px 20px 0 20px;
  position: relative;
  color: #fff;
  margin-bottom: 15px;
  background-color: #ffffff08;
  border-radius: 10px;

  border: 1px solid #ffffffc6;
}
.ViewMessages .Message::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);

  z-index: -1;
  width: 100%;
  height: 100%;
}
.ViewMessages .Message:nth-child(even) {
  border-radius: 20px 20px 20px 0;
}
.ViewMessages .Message:nth-child(odd) {
  border-radius: 20px 20px 0 20px;
}
.ViewMessages.Leader .Message {
  width: 600px;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 640px) {
  .ViewMessages.Leader .Message {
    width: 98%;
    margin-left: auto;
    margin-right: auto;
  }
}
/* .Dark .ViewMessages .Message {
  background-color: var(--VeryDarkColor);
} */

.ViewMessages .Message p {
  white-space: break-spaces;
  margin-bottom: 35px;
  width: calc(100% - 36px);
}
.ViewMessages .Message button.ToggleView {
  background-color: transparent;
  /* color: #000; */
  border: none;
}
.ViewMessages .Message button {
  color: #fff;
}
.ViewMessages .Message span.Time {
  position: absolute;
  right: 8px;
  bottom: 8px;
}
.ViewMessages.Show {
  max-height: fit-content;
  width: 600px;
  margin: auto;
  height: auto;
  margin-top: 30px;
}
.ViewMessages.Show.Leader {
  margin: 0;
}
#Notifications .SendSection {
  /* background-color: var(--LightColor); */
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  border: 1px solid #ffffffac;
  box-shadow: var(--BoxShadow);
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.Dark #Notifications .SendSection {
  background-color: var(--VeryDarkColor);
}
#Notifications .SendSection textarea {
  width: calc(100% - 70px);
  word-break: break-all;
  max-height: 200px;
  height: 30px;
}
@media (max-width: 1300px) {
  #Notifications,
  .ViewMessages.Show {
    width: 99%;
    padding-top: 15px;
    margin-top: 0;
  }
}
.Message[dir="ltr"] #dropdown-basic-button {
  right: 0;
  position: absolute;
}
.Message[dir="ltr"] .dropdown {
  right: 8px;
}
.Message[dir="rtl"] .dropdown {
  left: 8px;
}
.Message[dir="rtl"] #dropdown-basic-button {
  left: 0;
  position: absolute;
}
.Message .dropdown-toggle::after {
  display: none;
}
.Message #dropdown-basic-button {
  padding: 5px;
  margin: 0;
  top: -5px;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Message .dropdown-menu {
  padding: 0;
  min-width: 125px;
  max-width: 125px;
  width: 125px;
}
.Message .dropdown-menu ul {
  list-style: none;
  padding: 5px;
  margin: 0;
  width: 100%;
}
.Message .dropdown-menu ul li {
  width: 100%;
  margin-bottom: 5px;
  display: flex;
  justify-content: flex-end;
}
.Message .dropdown-menu ul li:last-child {
  margin-bottom: 0;
  color: #000;
}
.Message[dir="ltr"] .dropdown-menu ul li button {
  flex-direction: row-reverse !important;
}
.Message .dropdown-menu ul li button {
  width: 100%;
  justify-content: space-between !important;
}
.Message .dropdown-menu ul li button:hover span,
.Message .dropdown-menu ul li button:hover svg {
  color: #fff;
}
.Message .dropdown-menu ul li button span {
  margin: 0 5px;
  color: #000;
}
.Message .dropdown-menu ul li button svg {
  color: #000;
}
.Dark .Message .dropdown-menu {
  background-color: var(--VeryDarkColor);
}
