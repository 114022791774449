/* .DashBordShowSchool {
} */
.DashBordShowSchool .Groups {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
}
.DashBordShowSchool .Groups .Group {
  margin: 10px;
  padding: 15px;
  border-radius: 5px;
  width: 400px;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  border: 1px solid #ffffff9f;
  box-shadow: var(--BoxShadow);
}
.Dark .DashBordShowSchool .Groups .Group {
  background-color: var(--VeryDarkColor);
}
.DashBordShowSchool .Groups .Group .Leaders {
  margin-bottom: 15px;
}

.DashBordShowSchool .Groups .Group h4 {
  margin-bottom: 10px;
}
.DashBordShowSchool .Groups .Group .Students .Student,
.DashBordShowSchool .Groups .Group .Leaders .Leader {
  border: 1px solid #ffffff9f;
  box-shadow: var(--BoxShadow);
  margin-bottom: 5px;
  padding: 10px;
  border-radius: 5px;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
}
.Dark .DashBordShowSchool .Groups .Group .Students .Student,
.Dark .DashBordShowSchool .Groups .Group .Leaders .Leader {
  background-color: var(--DarkColor);
}
.Dark .DashBordShowSchool .Pagination nav ul li button {
  color: #fff;
  border: 1px solid #d8d8d8;
}
.Dark
  .DashBordShowSchool
  .Pagination
  nav
  ul
  li
  button.css-1y7coo4-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  border: 2px solid rgba(25, 118, 210, 0.5);
  background-color: rgba(25, 118, 210, 0.12);
}
.DashBordShowSchool .Pagination.Ar nav ul {
  flex-direction: row-reverse;
}
.CreateSchool {
  width: 400px;
  margin: 20px auto;
  padding: 30px 20px;
  border-radius: 10px;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  border: 1px solid #ffffff82;
  box-shadow: var(--BoxShadow);
}
.Dark .CreateSchool {
  background-color: var(--DarkColor);
}

@media (max-width: 790px) {
  .CreateSchool {
    width: 97%;
  }
}
@media (max-width: 500px) {
  .DashBordShowSchool .Groups > div {
    width: 100%;
  }
  .DashBordShowSchool .Groups .Group {
    width: 97%;
  }
}
.DashBordShowSchool .Groups .Group p {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.DashBordShowSchool .header button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  padding: 10px;
}
.DashBordShowSchool .header .Actions {
  width: 106px;
}
.DashBordShowSchool .header div:first-child {
  width: calc(100% - 106px);
}
.DashBordShowSchool .header div:first-child h2 {
  width: calc(100% - 92px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
/* .DashBordShowSchool .Groups .Group .Students {
  counter-set: Counter1;
}
.DashBordShowSchool .Groups .Group .Students .Student::before {
  content: counter(Counter1);
} */
