#YourGroupPage {
  margin-top: 20px;
  padding: 10px;
}
#YourGroupPage.User {
  padding: 5px;
}
#YourGroupPage.Leader {
  width: calc(100% - 300px);
}
#YourGroupPage.Left {
  margin-right: auto;
}
#YourGroupPage.Right {
  margin-left: auto;
}
@media (max-width: 780px) {
  #YourGroupPage.Leader {
    width: calc(100% - 60px);
  }
}
#YourGroupPage .Users {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 400px;
}
#YourGroupPage .Score {
  -webkit-backdrop-filter: blur(30px);
  margin-top: 10px;
  backdrop-filter: blur(30px);
  border: 1px solid #ffffff8f;
  padding: 10px;
  border-radius: 5px;
  width: 400px;
  margin: 0 auto;
  margin-bottom: 10px;
}
.ParentOfForms > div {
  width: 400px;
}
@media (max-width: 760px) {
  #YourGroupPage .Score {
    width: 98%;
  }
  .ParentOfForms > div {
    width: 100%;
  }
}
#YourGroupPage .Users .User {
  padding: 10px;
  border: 1px solid #ffffff99;
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(25px);
  border-radius: 5px;
  margin-bottom: 5px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#YourGroupPage .Title {
  border: 1px solid #ffffff99;
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(25px);
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
}
#YourGroupPage .Users .User button {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 50%;
  color: #fff;
}
#YourGroupPage .Users .User h4 {
  margin-bottom: 0;
}
.Dark #YourGroupPage .Users .User {
  background-color: var(--VeryDarkColor);
}

#YourGroupPage .Forms {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#YourGroupPage .Forms #Form {
  width: 400px;
  display: flex;
  align-items: center;
  border: 1px solid #ffffff99;
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(25px);
  box-shadow: var(--BoxShadow);
  padding: 10px;
  border-radius: 5px;
  position: relative;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 20px;
}
@media (max-width: 600px) {
  #YourGroupPage .Forms #Form {
    width: 100%;
  }
}
.Dark #YourGroupPage .Forms #Form {
  background-color: var(--VeryDarkColor);
}
#YourGroupPage .Forms #Form .Form {
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid #ffffff99;
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(25px);
  box-shadow: var(--BoxShadow);
  padding: 10px;
  border-radius: 5px;
  position: relative;
  justify-content: space-between;
}
#YourGroupPage .Forms #Form > span:last-child {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-weight: 900;
}
#YourGroupPage .Forms .Form > span:last-child.Answered {
  color: #000000;
  background-color: #ffde4d;
  box-shadow: 0 0 8px 1px #ffde4d;
}

#YourGroupPage .Forms .Form > span:last-child.NotAnswered {
  color: #fff;
  background-color: #a91d3a;
  box-shadow: 0 0 8px 3px #a91d3a;
}
#YourGroupPage .Forms .Form div {
  width: calc(100% - 60px);
  display: flex;
  align-items: center;
  flex-direction: column;
}
#YourGroupPage .Forms .Form div span {
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
@media (max-width: 500px) {
  #YourGroupPage .Users {
    width: 100%;
    align-items: center;
  }
  #YourGroupPage .Forms .Form {
    width: 98%;
  }
}
