#QrCodePage.Admin {
  padding: 0 10px;
}
#QrCodePage.Admin svg {
  width: 200px;
  height: 200px;
  position: absolute;
  z-index: 1;
  border: 5px solid #fff;
  left: 1.2cm;
  top: 4cm;
}
#QrCodePage.Admin .QrCode {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 13cm;
  width: 19cm;
  padding: 15px;
  margin-bottom: 10px;
  background-image: url("http://localhost:3000/Images/a.png");
  position: relative;
  color: #fff;
  padding: 1cm;
  flex-direction: column;
  background-position: center;
  background-size: cover;
}
/* #QrCodePage.Admin .QrCode::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: 100%;
  background-position: 0 0;
  z-index: 1;
} */
#QrCodePage.Admin .QrCode img {
  width: 120px;
  position: absolute;
  right: 1cm;
  top: 1cm;
  z-index: 4;
}

/* #QrCodePage.Admin .QrCode div {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  margin-right: auto;
  margin-left: 18px;
  margin-top: auto;
  position: relative;
  z-index: 4;
  display: none;
} */
#QrCodePage .QrCode h5.text-center.usename {
  position: absolute;
  bottom: 2.2cm;
  z-index: 22;
  color: #fff;
  left: 1.2cm;
  font-size: 25px;
}
#QrCodePage .QrCode h5.text-center.id {
  position: absolute;
  bottom: 1.2cm;
  z-index: 22;
  color: #fff;
  left: 1.2cm;
  font-size: 25px;
}
#QrCodePage.Admin .QrCode div * {
  font-size: 29px;
  text-transform: capitalize;
}
#QrCodePage.Admin .QrCode button {
  position: absolute;
  right: -200px;
  top: 0;
}
#QrCodePage.Admin .Vers {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 12cm;
  width: 18cm;
  background-color: #c8acd6;
  padding: 1cm;
  margin-bottom: 10px;
  position: relative;
  color: #fff;
  flex-direction: column;
}
#QrCodePage.Admin .Vers::before {
  content: "";
  background-image: linear-gradient(rgba(0, 0, 0, 0.59), rgba(0, 0, 0, 0.815)),
    url("https://siham-bied-jabaar.netlify.app/Images/BG.jpg");
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: rotateY(180deg);
  background-size: cover;
  background-position: 0 0;
  z-index: 1;
}
#QrCodePage.Admin .Vers p {
  position: relative;
  z-index: 1;
  text-shadow: 2px 2px 2px #9f909095;
  text-align: center;
  direction: rtl;
}
#QrCodePage.Admin .Vers p:first-child {
  font-size: 80px;
  margin-top: 20px;
}
#QrCodePage.Admin .Vers p:last-child {
  font-size: 50px;
  margin-bottom: 20px;
}
/* #QrCodePage.Admin span {
  position: absolute;
  right: 190px;
  z-index: 3;
  bottom: 110px;
  font-size: 40px;
} */
