.Modal .modal-content {
  background-color: transparent;
  color: #fff;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  border: 1px solid #ffffff9f;
}
.Modal.dark .modal-header {
  border-bottom: 2px solid var(--VeryDarkColor) !important;
}
.Modal.dark .modal-footer {
  border-top: 2px solid var(--VeryDarkColor) !important;
}
.RTL .modal-footer {
  direction: ltr;
}

.Lalezar .modal-footer button span {
  display: flex;
  align-items: center;
  justify-content: center;
}
.Lalezar .modal-footer button span span {
  transform: translateY(2px);
}
.Modal .modal-content button {
  color: #fff;
}
.Modal .modal-content input,
.Modal .modal-content textarea {
  background-color: transparent;
  color: #fff;
  border-radius: 0;
  border: 0;
  border-bottom: 2px solid #fff;
  outline: 0;
  box-shadow: none !important;
  position: relative;
  transition: 0.4s;
  padding: 8px 3px;
}
.Modal .modal-content input::placeholder,
.Modal .modal-content textarea::placeholder {
  color: #fff;
}
.Modal .modal-content input:focus,
.Modal .modal-content textarea:focus {
  border-bottom: 2px solid #709df7;
}
/* sa */
